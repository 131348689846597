export function useDataLoading({
  getterName, searchable = true, paged = true, parameterize = false,
}) {
  return {
    data() {
      return {
        isLoading: false,
        loadingError: { show: false, text: '' },
        allowGettingData: true,
      };
    },
    watch: {
      allowGettingData: {
        handler(newValue) {
          if (!newValue) {
            return;
          }
          this.$nextTick(async () => {
            try {
              await this.getData();
            } finally {
              this.allowGettingData = false;
            }
          });
        },
        immediate: true,
      },
      '$route.params': {
        handler() {
          if (parameterize) {
            this.allowGettingData = true;
          }
        },
        deep: true,
      },
      '$route.query': {
        handler() {
          this.allowGettingData = true;
        },
        deep: true,
      },
    },
    methods: {
      $_fetchData() {
        this.allowGettingData = true;
      },

      $_fetchDataWithoutSpinner() {
        this.getData({ withLoadingSpinner: false });
      },

      async getData({ withLoadingSpinner = true } = {}) {
        const response = { ...this.$route.query };

        if (searchable) {
          Object.assign(response, {
            ...response,
            ...this.parseSearchData(this.searchData),
          });
        }

        if (parameterize) {
          Object.assign(response, {
            ...response,
            ...this.$route.params,
          });
        }

        if (paged) {
          response.pageNum = response.pageNum || '1';
        }

        if (withLoadingSpinner) {
          this.isLoading = true;
        }

        this.loadingError.show = false;

        try {
          await this[getterName](response);
          return Promise.resolve();
        } catch (e) {
          Object.assign(this, {
            loadingError: { show: true, text: e },
          });
          return Promise.reject();
        } finally {
          if (withLoadingSpinner) {
            this.isLoading = false;
          }
        }
      },
    },
  };
}

export default {};
