// route query params for application using
const defaultSystemQueryParams = ['pageNum'];

/**
 * Getter for initial states for search data
 * If user not set state value, define undefined value
 *
 * @param states {Array} array of strings or objects
 *
 * @returns {Object}
 */
const getSearchDataInitialStates = (states) => {
  const result = {};
  states.forEach((state) => {
    if (typeof state === 'string') {
      result[state] = undefined;
    } else {
      result[state.prop] = state.value;
    }
  });
  return result;
};

/**
 * Generated mixin for working with search component
 * This is basic configuration, you can modify some properties
 * For more information see documentation for VBlackerTheme Search component
 *
 * @param {Array} initialComponentStates includes objects with fields:
 * prop - property name, value - initial value
 * @param {Array} [systemQueryParams] - name of props,
 * includes to route query and not needed in searchData
 * @param {Array} searchFieldsConfig config for drawing search component,
 * for more info read documentation for VBlackerTheme Search component
 * @param { Object } customTypes - config for type parse, use values
 * number, boolean, booleanString, array
 *
 * @returns {Object} local named mixin for Vue component
 */
export function useSearch({
  systemQueryParams = defaultSystemQueryParams,
  defaultRouteQuery = { pageNum: '1' },
  initialSearchDataStates = [],
  searchFieldsConfig = [],
  customTypes = {},
  passedQueryParams = [],
} = {}) {
  return {
    data() {
      return {
        searchData: this.generateSearchData(this.$route),
      };
    },
    computed: {
      searchFieldsConfig() {
        return searchFieldsConfig.map(field => ({ ...field, type: field.type || 'text' }));
      },
      $$routeQueryWithoutSystemParams() {
        const { query } = this.$route;
        const routeQueryKeys = Object.keys(query);
        const result = {};
        routeQueryKeys.forEach((key) => {
          if (!systemQueryParams.includes(key)) {
            result[key] = query[key];
          }
        });
        return result;
      },
    },
    watch: {
      $$routeQueryWithoutSystemParams: {
        handler(query) {
          Object.assign(this, {
            searchData: this.generateSearchData({ query }),
          });
        },
        deep: true,
      },
    },
    methods: {
      // if user not set this method
      generateSearchData({ query }) {
        const initialStates = getSearchDataInitialStates(initialSearchDataStates);
        const initialStatesKeys = Object.keys(initialStates);
        const result = {};
        initialStatesKeys.forEach((key) => {
          const { [key]: prop = initialStates[key] } = query;

          if (prop === initialStates[key] || passedQueryParams.includes(key)) {
            result[key] = prop;
            return;
          }

          if (customTypes[key] === 'number') {
            result[key] = Number(prop);
          } else if (customTypes[key] === 'boolean') {
            result[key] = Boolean(prop);
          } else if (customTypes[key] === 'booleanString') {
            const v = (prop || '').toLowerCase();
            const isBoolStr = v === 'true' || v === 'false';

            result[key] = isBoolStr ? JSON.parse(v) : false;
          } else if (customTypes[key] === 'array') {
            result[key] = Array.isArray(prop) ? prop : [prop];
          } else {
            result[key] = prop;
          }
        });
        return result;
      },
      // can be reassign for customization
      parseSearchData(data) {
        const dataKeys = Object.keys(data);
        const result = {};

        dataKeys.forEach((key) => {
          if (key === 'date' && Array.isArray(data[key])) {
            [result.startDate, result.endDate] = data[key]; // resolve array dates prop
          } else if (Array.isArray(data[key])) {
            if (data[key].some(v => Boolean(v))) {
              result[key] = data[key];
            }
          } else if (data[key] || typeof data[key] === 'boolean') {
            result[key] = data[key];
          }
        });

        return result;
      },
      onResetSearch() {
        Object.assign(this, {
          searchData: { ...getSearchDataInitialStates(initialSearchDataStates) },
        });
        this.$router.push({
          query: defaultRouteQuery,
        }, null, () => {
          this.allowGettingData = true;
        });
      },
      onSearch() {
        this.$router.push({
          query: {
            pageNum: this.$route.query.pageNum ? '1' : undefined,
            ...this.parseSearchData(this.searchData),
          },
        }, null, () => {
          this.allowGettingData = true;
        });
      },
    },
  };
}

export default {};
