import { mapState, mapActions } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import { generateXHRError } from '@helpers';

const dataLoadingOptions = { getterName: 'getTrackingSubscriptions' };
const paginationOptions = { itemsName: 'subscriptions' };
const searchOptions = {
  initialSearchDataStates: ['orderId', 'trackingNumber'],
  searchFieldsConfig: [
    { value: 'orderId', label: 'Order ID', flex: 'lg6' },
    { value: 'trackingNumber', label: 'Tracking Number', flex: 'lg6' },
  ],
};
const trackingSubscriptionsListHeaders = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Tracking Number', value: 'trackingNumber' },
  { text: 'Last Attempt', value: 'lastAttempt' },
  { text: 'Next Attempt', value: 'nextAttempt' },
  { text: 'Cancelation Reason', value: 'cancelationReason' },
  { text: '', value: 'actions', width: '5%' },
]);

export default {
  name: 'TrackingInformation',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],
  data() {
    return {
      trackingSubscriptionsListHeaders,
      subscriptionsFilter: this.$route.query.state || 'all',
    };
  },
  computed: {
    ...mapState('tracking', ['subscriptions']),
  },
  methods: {
    ...mapActions('tracking', ['getTrackingSubscriptions']),
    useSubscriptionsFilter(value) {
      this.subscriptionsFilter = value;
      this.$router.push({
        query: { ...this.$route.query, state: value },
      }, null, () => {});
      this.allowGettingData = true;
    },
    async getData() {
      const subscriptionsFilters = { enabled: true, all: null, disabled: false };

      this.isLoading = true;
      this.loadingError.show = false;

      try {
        await this.getTrackingSubscriptions({
          ...this.parseSearchData(this.searchData),
          pageNum: this.$route.query.pageNum || '1',
          isActive: subscriptionsFilters[this.subscriptionsFilter],
        });
        return Promise.resolve();
      } catch (e) {
        Object.assign(this, {
          loadingError: { show: true, text: e },
        });
        return Promise.reject();
      } finally {
        this.isLoading = false;
      }
    },
    async sendTrackingSubscriptionProcessedRequest(id) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.$http.post(`tracking/subscriptions/${id}/process`);
        this.allowGettingData = true;
        this.$VBlackerTheme.notification
          .success('Tracking subscription successfully processed');
      } catch (e) {
        this.$VBlackerTheme.notification.error(generateXHRError(e));
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
